<template lang="pug">
    .click-the-diff
      v-card.pa-5(outlined)
        .words.d-flex.flex-wrap
          .word(v-for="word, wordIdx in words", :key="wordIdx + word.value", :data-type="word.type", :data-correct="word.correct", :class="[word.type]", @click="handleClick").d-flex.align-end
            span.spacer
            .text {{word.value}}

        .count.mt-5.text-center
          v-row(dense).flex-column-reverse.flex-md-row
            v-col(cols="12", md="6").text-md-left
                a(href="#" @click.prevent="$emit('restart')").ml-2.text-decoration-none.hover-underline
                  em Restart articles practice?
            v-col(cols="12", md="6").text-md-right
              template(v-if="numFound < errorCount")
                em(:style="{color: $vuetify.theme.themes.light.warning}") You have found {{numFound}} out of {{errorCount}} issues
              template(v-else)
                em(:style="{color: $vuetify.theme.themes.light.success}") Nice! You found all the issues in this part.

        template(v-if="offerTryMore")
          v-divider.my-4
          v-row.buttons(align="center" justify="center")

            v-col.restart(cols="12" md="4")
              v-btn(block :disabled="!offerGoBack" text @click="$emit('doGoBack')")
                v-icon.mr-1 mdi-chevron-left
                | Go Back

            v-col.offer-hint(cols="12" md="4")
              v-btn.mb-1.mb-md-0(block text color="primary", :disabled="numFound === errorCount" @click="doHint")
                v-icon.mr-1 mdi-lightbulb-on
                | Get a hint

            v-col.offer-try-another(v-if="!done", cols="12" md="4")
              v-btn(block :color="foundAllErrors ? 'success' : 'grey lighten-2'" :text="tryAnotherOneIsDisabled", :depressed="!tryAnotherOneIsDisabled",  @click="$emit('loadMore')" :disabled="tryAnotherOneIsDisabled")
                span(:class="{'font-weight-bold': foundAllErrors}") {{offerTryAnother ? 'Try another one' : 'Next exercise'}}
                v-icon.ml-1 mdi-chevron-double-right

</template>

<script>
import { annotate } from "rough-notation";
import { diffWords } from "diff";

export default {
  name: "ClickTheDiff",
  props: {
    incorrect: { type: String, required: true },
    correct: { type: String, required: true },
    offerTryMore: { type: Boolean, default: false },
    offerGoBack: { type: Boolean, default: false },
    offerTryAnother: { type: Boolean, default: false },
    done: { type: Boolean, default: false },
  },
  data() {
    return {
      diff: null,
      numFound: 0,
      annotations: [],
    };
  },
  created() {
    this.diff = diffWords(this.incorrect, this.correct, { ignoreCase: true });
  },
  computed: {
    tryAnotherOneIsDisabled() {
      return this.numFound < Math.ceil(this.errorCount / 2);
    },
    errorCount() {
      let count = 0;
      for (let i = 0; i < this.diff.length; i++) {
        const thisItm = this.diff[i];
        const nextItm = this.diff[i + 1];

        const isReplacement = thisItm.removed && nextItm.added;
        const isAddition = thisItm.added;
        const isRemoval = thisItm.removed;

        if (!thisItm.counted) {
          if (isReplacement) {
            count++;
            nextItm.counted = true;
          } else if (isAddition || isRemoval) count++;

          thisItm.counted = true;
        }
      }

      return count;
    },
    diffToElements() {
      if (!this.diff || this.diff.length < 1) return [];

      return this.diff.map((itm) => {
        let type = null;
        if (itm.added) type = "waiting-to-add";
        else if (itm.removed) type = "waiting-to-remove";
        return { value: itm.value, type };
      });
    },
    words() {
      if (!this.diffToElements || this.diffToElements.length < 1) return [];

      const wordsWithAddedAndRemoved = this.diffToElements
        .map((element) => {
          const wordsRaw = element.value.split(" ");
          const objWords = wordsRaw
            .map((word) => {
              if (!word) return null;
              const obj = { value: word };
              if (element.type) obj.type = element.type;
              return obj;
            })
            .filter(Boolean);

          return objWords;
        })
        .flat();

      const wordsWithAddedRemovedAndReplaced = [];
      for (let i = 0; i < wordsWithAddedAndRemoved.length; i++) {
        const thisWord = wordsWithAddedAndRemoved[i];
        const nextWord = wordsWithAddedAndRemoved[i + 1];

        if (thisWord.type !== "ignore") {
          const obj = { value: thisWord.value };
          if (
            thisWord.type === "waiting-to-remove" &&
            nextWord &&
            nextWord.type === "waiting-to-add"
          ) {
            obj.type = "replaceWith";
            obj.correct = nextWord.value;
            nextWord.type = "ignore";
          } else if (thisWord.type) {
            obj.type = thisWord.type;
          }
          wordsWithAddedRemovedAndReplaced.push(obj);
        }
      }

      return wordsWithAddedRemovedAndReplaced;
    },
    foundAllErrors() {
      return this.numFound >= this.errorCount;
    },
  },
  methods: {
    annotationRemoveElement(e) {
      // console.log("removing element e", e);
      if (e.classList.contains("text")) {
        e.parentNode.classList.remove("waiting-to-remove");
        e.parentNode.classList.add("removed");
        delete e.parentNode.dataset.type;
        e.parentNode.dataset.type = "removed";
      } else {
        e.classList.remove("waiting-to-remove");
        e.classList.add("removed");
        delete e.dataset.type;
        e.dataset.type = "removed";
      }
      const annotation = annotate(e, {
        type: "crossed-off",
        color: "red",
      });
      this.refreshAnnotations();
      annotation.show();

      if (!this.annotations.includes(annotation)) {
        this.annotations.push(annotation);
      }
    },

    annotationAddElement(e) {
      // console.log("adding element e", e);
      if (e.classList.contains("text")) {
        e.parentNode.classList.remove("waiting-to-add");
        e.parentNode.classList.add("added");

        delete e.parentNode.dataset.type;
        e.parentNode.dataset.type = "added";
      } else {
        e.classList.remove("waiting-to-add");
        e.parentNode.classList.add("added");

        delete e.dataset.type;
        e.dataset.type = "removed";
      }

      const annotation = annotate(e, {
        type: "box",
        color: "green",
        strokeWidth: 2,
      });
      this.refreshAnnotations();
      annotation.show();

      if (!this.annotations.includes(annotation)) {
        this.annotations.push(annotation);
      }
    },

    handleClick(e) {
      // console.log("handling click e.target", e.target);
      if (
        !e.target.classList.contains("added") &&
        !e.target.classList.contains("removed")
      ) {
        if (e.target && e.target.classList.contains("spacer")) {
          this.handleClickSpacer(e);
        } else {
          this.handleClickWord(e);
        }
      }
    },

    handleClickWord(e) {
      const parentWord = e.target.closest(".word");

      const { dataset } = parentWord;
      if (dataset.type === "replaceWith") {
        // console.log("needs to be replaced...", parentWord);

        this.annotationRemoveElement(e.target);
        this.numFound++;
        setTimeout(() => {
          parentWord.querySelector(".text");

          const newElem = document.createElement("div");
          newElem.innerText = dataset.correct;
          newElem.classList.add("text");
          newElem.classList.add("waiting-to-add");

          parentWord.appendChild(newElem);

          this.annotationAddElement(newElem);
        }, 500);

        parentWord.classList.remove("replaceWith");
        parentWord.classList.add("replaced");
      } else if (dataset.type === "waiting-to-remove") {
        this.annotationRemoveElement(e.target);
        this.numFound++;
      } else if (dataset.type === "waiting-to-add") {
        // These are outside the screen and normally not user-clickable
        // But we trigger a click on these when calling doHint(), so they need to handle that

        // console.log("user clicked", e.target);
        this.annotationAddElement(e.target.querySelector(".text"));
        this.numFound++;
      } else {
        // all good
        parentWord.querySelector(".text").classList.add("success");

        setTimeout(() => {
          parentWord.querySelector(".text").classList.remove("success");
        }, 1000);
      }
    },

    handleClickSpacer(e) {
      // console.log("clicked spacer", e.target);
      const parentWord = e.target.closest(".word");
      if (!parentWord) return;
      const wordBeforeThat = parentWord.previousSibling;
      // console.log("wordBeforeThat", wordBeforeThat);
      if (wordBeforeThat.classList.contains("waiting-to-add")) {
        // console.log("adding ....");
        const textElem = wordBeforeThat.querySelector(".text");
        this.annotationAddElement(textElem);
        this.numFound++;
      }
    },
    refreshAnnotations() {
      this.annotations.forEach((annotation) => {
        annotation.animate = false;
        annotation.show();
      });
    },
    doHint() {
      const waitingToReplace = document.querySelector(".replaceWith .text");
      const waitingToAdd = document.querySelector(
        "[data-type='waiting-to-add']"
      );
      const waitingToRemove = document.querySelector(
        "[data-type='waiting-to-remove'] .text"
      );

      if (waitingToReplace) {
        waitingToReplace.classList.add("hinted");
        waitingToReplace.click();
      } else if (waitingToRemove) {
        waitingToRemove.classList.add("hinted");
        waitingToRemove.click();
      } else if (waitingToAdd) {
        waitingToAdd.querySelector(".text").classList.add("hinted");
        waitingToAdd.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";

.click-the-diff {
  background: $secondary;
  // padding: 2rem;
  // padding-right: 2rem;
  font-size: 1.75rem;
  line-height: 2;
  cursor: default;
}

.waiting-to-add {
  opacity: 0;
  width: 1px;
  overflow: hidden;
  bottom: 0;
  right: 0;
  position: fixed;
}

.spacer {
  display: inline-block;
  height: 100%;
  width: 1.22rem;
  border-bottom: 2px solid rgba(255, 166, 0, 0.4);
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.spacer:hover {
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.added {
  margin-right: 7px;

  font-weight: bold;
  color: green;
}

.removed .text {
  font-weight: bold;
  color: Red;
  margin-right: 1rem;
}

.count,
.count * {
  font-size: 1rem;
}

.word .text:hover {
  transition: 0.3s all ease-in-out;
  background: orange;
  color: white;
  cursor: pointer;
}
.hinted {
  background: yellow;
}

a.hover-underline {
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 2px dotted;
}

a.hover-underline:hover {
  transition: 0.3s all ease-in-out;
  color: black;
  border-bottom: 2px solid black;
}

::v-deep .word .text,
::v-deep .word .spacer,
::v-deep .word .text.waiting-to-add {
  font-size: 1.5rem !important;
}
@media only screen and (min-width: 900px) {
  .spacer:hover {
    transition: 0.3s all ease-in-out;
    border-top: 3px solid orange;
    background: rgba(255, 166, 0, 0.4);
  }
}
</style>
